<template>
  <div class="flex flex-col lg:flex-row gap-y-5 gap-x-11">
    
<FormKitLazyProvider config-file="true" :default-config="false">
<div>
      <h2 class="text-tekstovyy text-6 lg:text-7.5 font-700">Войти</h2>

      <FormKit
        type="form"
        form-class="flex flex-col gap-5 lg:gap-7 mt-4 lg:mt-7"
        :actions="false"
        :incomplete-message="false"
        :config="{ messagesClass: 'absolute top-full left-0 w-full', outerClass: 'relative', inputClass: '$remove:text-tekstovyy text-firmennyy $remove:placeholder:text-#AAB8C1 placeholder-firmennyy/50 group-data-[invalid]:text-#ED6A41 group-data-[invalid]:placeholder-#ED6A41/50', innerClass: 'bg-plashka-green $remove:border-border border-plashka-green focus-within:border-firmennyy/20 group-data-[invalid]:bg-#ED6A41/10 group-data-[invalid]:border-#ED6A41/50' }"
        #="{ state }"
        @submit="login"
      >
        <FormKit
          name="email"
          type="email"
          placeholder="email"
          validation="required"
          validation-label="E-mail"
        />
        <FormKit
          name="password"
          type="password"
          placeholder="Пароль"
          validation="required:trim"
          validation-label="Пароль"
        >
        </FormKit>
        <RecaptchaCustom id="recaptcha-auth" />
        <Button
          :loading="state?.loading"
          :disabled="state?.loading"
        >
          Войти
        </Button>
      </FormKit>
    </div>
    <div class="flex lg:flex-col items-center shrink-0 gap-x-3.5 gap-y-4 max-lg:order-first lg:max-w-35">
      <img
        width="140"
        height="140"
        class="size-22.5 lg:size-35"
        src="/imgs/authentication/auth.png"
        alt=""
      >
      <p class="text-firmennyy text-3 font-500 leading-1.3 lg:text-center max-lg:max-w-35">{{ `Добро пожаловать &nbsp; рады видеть \n вас снова!` }}</p>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
// import { vMaska } from 'maska';
// Добавить маску ASAP
import type { FetchError } from 'ofetch';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { authenticationModalTypeKey } from './injectionKeys';
import type { UserLoginRequestType } from '~/composables/user/types/UserTypes';
const { $toast, $recaptcha } = useNuxtApp();
const modalType = inject(authenticationModalTypeKey);
const RecaptchaCustom = defineAsyncComponent(() => import('~/components/common/RecaptchaCustom.vue'));
const emit = defineEmits(['success']);

const { userLogin } = useUserAuth();

const login = async (form: UserLoginRequestType) => {
  console.log(form);
  const token = await $recaptcha.getResponse().catch((err:string) => { console.error(err); });
  const res = await userLogin({
    password: form.password,
    email: form.email,
    'g-recaptcha-response': token
  })
    .catch((err: FetchError) => {
      $toast.error(err.data.message || 'server error');
    });

  if (!res) { return; } else {
    navigateTo('/');
  }
  emit('success');
};
</script>
